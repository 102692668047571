<template>
  <div>
    <div class="flex bg-gray-100 min-h-screen relative overflow-hidden shadow rounded">
      <div v-for="(column, i) in columns" class="bg-white w-1/4 border-r" :key="`column-${i}`">
        <div class="flex justify-end bg-gray-100 border-b p-2">
          <button
            class="block rounded px-3 py-1 bg-gray-100 border"
            v-if="columns.length < 4"
            @click="addColumn()"
          >
            <FontAwesomeIcon :icon="faPlus" />
          </button>
          <button
            class="block rounded px-3 py-1 bg-gray-100 border ml-2"
            v-if="columns.length !== 1"
            @click="removeColumn(i)"
          >
            <FontAwesomeIcon :icon="faTrash" />
          </button>
        </div>
        <div class="p-4">
          <FormInsights
            v-model="columns[i].model"
            @submit="getColumn(i)"
            cohorts="listings"
            :isLoading="columns[i].isLoading"
          />
        </div>

        <template v-if="columns[i].data.listings">
          <div v-for="(data, title) in columns[i].data.listings" :key="`kpi-${title}`">
            <div class="bg-gray-700 text-white font-medium px-3 py-1 text-left">{{ title | startCase }}</div>
            <table class="w-full">
              <tbody>
                <tr
                  v-for="(value, key, i) in data"
                  :key="`kpi-${title}-${key}`"
                  class="border-white"
                  :class="{ 'bg-gray-100': i % 2 == 0, 'bg-gray-200': i % 2 != 0 }"
                >
                  <th class="font-normal px-3 py-1 text-left text-gray-600">{{ key | startCase }}</th>
                  <td class="font-medium px-3 py-1">{{ value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/lib/api'
import { cloneDeep, startCase } from 'lodash'
import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons'
import FormInsights from '@/components/forms/FormInsights'

export default {
  name: 'InsightsKpis',
  components: {
    FormInsights
  },
  data() {
    return {
      columns: [],
      faPlus,
      faTrash
    }
  },
  created() {
    this.setColumns()
  },
  computed: {
    compQueryUrl() {
      let query = []
      let columns = cloneDeep(this.columns)
      //query.startDate = this.$date(query.startDate).format('DD-MM-YYYY')
      //query.endDate = this.$date(query.endDate).format('DD-MM-YYYY')
      columns.forEach(column => {
        query.push(column.model)
      })

      return JSON.stringify(query)
    }
  },
  filters: {
    startCase(value) {
      return startCase(value)
    }
  },
  methods: {
    addColumn() {
      this.columns.push(new Api({ from: '01-01-2020', to: '31-12-2020', include: ['livestock'], exclude: [] }))
    },

    removeColumn(i) {
      if (confirm('Are you sure you want to delete this column?')) this.columns.splice(i, 1)
    },

    setColumns() {
      let q = this.$route.query.q

      console.log(q)

      if (q) {
        let i = 0
        q = JSON.parse(q)

        console.log(q)

        q.forEach(column => {
          this.addColumn()
          this.columns[i].model = column
          i++
        })
      } else {
        this.addColumn()
      }
    },

    async getColumn(i) {
      await this.columns[i].get('http://localhost:3000/local/analytics/v1/kpis')

      this.$router.push({ query: { q: this.compQueryUrl } })
    }
  }
}
</script>
